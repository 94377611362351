import { AuthUserReducerType, AuthUserStateTypes } from './AuthUserReducer.types'

export const AuthUserReducer: AuthUserReducerType = (state, action) => {
  switch (action.type) {
    case AuthUserStateTypes.CreateCognitoUserSessionData:
      return {
        ...state,
        CognitoUserSessionData: action.payload.CognitoUserSessionData,
        isAuthenticated: true,
        CognitoUser: action.payload.CognitoUser
      }
    default:
      return state
  }
}
