import React, { FC } from 'react'
import LoggedMenu from '../LoggedMenu'
import OpenMenu from '../OpenMenu'
import OpenHomeMenu from '../OpenHomeMenu'
import { MenuWrapperProps } from './MenuWrapper.types'

const MenuWrapper: FC<MenuWrapperProps> = ({ children, menuType }) => {
  const menuOptions = {
    loggedMenu: <LoggedMenu>{children}</LoggedMenu>,
    openMenu: <OpenMenu>{children}</OpenMenu>,
    openHomeMenu: <OpenHomeMenu>{children}</OpenHomeMenu>
  }
  return menuOptions[menuType]
}

export default MenuWrapper
