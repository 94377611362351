import { ProjectRegistrationInitialState } from 'components/ProjectRegistration/ProjectRegistrationStore'
import { MyProjectsInitialState } from 'components/MyProjects/MyProjectsStore'
import { RoutesInitialState } from 'routes/RoutesStore'

import { InitialStateType } from './initialState.types'
import { AuthUserInitialState } from './AuthUserInitialState'

export const initialState: InitialStateType = {
  AuthUser: AuthUserInitialState,
  ProjectRegistration: ProjectRegistrationInitialState,
  Routes: RoutesInitialState,
  MyProjects: MyProjectsInitialState
}

export default initialState
