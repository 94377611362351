import { ProjectRegistrationInitialState } from '../ProjectRegistrationInitialState'
import { ProjectRegistrationReducerType, ProjectRegistrationStateTypes } from './ProjectRegistrationReducer.types'

export const ProjectRegistrationReducer: ProjectRegistrationReducerType = (state, action) => {
  switch (action.type) {
    case ProjectRegistrationStateTypes.CreateProjectRegistrationFormData:
      return { ...state, ProjectRegistrationForm: action.payload.ProjectRegistrationForm }
    case ProjectRegistrationStateTypes.CreateProjectData:
      return { ...state, CurrentProject: action.payload.CurrentProject }
    case ProjectRegistrationStateTypes.OnChangeProjectRegistrationInput:
      return { ...state, ProjectRegistrationForm: { ...state.ProjectRegistrationForm, ...action.payload } }
    case ProjectRegistrationStateTypes.ProjectOnChange:
      return { ...state, CurrentProject: { ...state.CurrentProject, ...action.payload } }
    case ProjectRegistrationStateTypes.ResetProjectRegistrationFormData:
      return { ...ProjectRegistrationInitialState }
    default:
      return state
  }
}
