import React, { FC, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import EventEmitter from 'eventemitter3'
import { EventErrorPopupPropsType, EventErrorPopupEmitterType } from './EventPopup.types'

export const popupEmitter = new EventEmitter()

const EventErrorPopup: FC<EventErrorPopupPropsType> = ({ showPopupAlert = false }) => {
  const [showAlert, showAlertSetter] = useState(false)
  const [errorMessage, errorMessageSetter] = useState('')
  const [alertTitle, alertTitleSetter] = useState('')
  const [alertType, alertTypeSetter] = useState('')
  const onConfirm = () => showAlertSetter(false)

  useEffect(() => {
    popupEmitter
      .on('errorPopup', ({ showPopupErrorAlert, title, message }: EventErrorPopupEmitterType) => {
        showAlertSetter(showPopupErrorAlert)
        errorMessageSetter(message)
        alertTitleSetter(title)
        alertTypeSetter('danger')
      })
      .on('infoPopup', ({ showPopupErrorAlert, title, message }: EventErrorPopupEmitterType) => {
        showAlertSetter(showPopupErrorAlert)
        errorMessageSetter(message)
        alertTitleSetter(title)
        alertTypeSetter('info')
      })
    return () => {
      popupEmitter.off('errorPopup').off('infoPopup')
    }
  }, [])

  useEffect(() => {
    showAlertSetter(showPopupAlert)
  }, [showPopupAlert])

  return (
    <>
      {showAlert && alertType === 'danger' && (
        <SweetAlert
          type="danger"
          title={alertTitle}
          btnSize="sm"
          confirmBtnText="  OK  "
          onConfirm={onConfirm}
          closeOnClickOutside
        >
          {errorMessage}
        </SweetAlert>
      )}
      {showAlert && alertType === 'info' && (
        <SweetAlert
          type="info"
          title={alertTitle}
          btnSize="sm"
          confirmBtnText="  OK  "
          onConfirm={onConfirm}
          closeOnClickOutside
        >
          {errorMessage}
        </SweetAlert>
      )}
    </>
  )
}

export default EventErrorPopup
