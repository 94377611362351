import { DispatchPropsType } from 'store/AppContext.types'
import { ActionMap } from 'store/helpers'

import { MyProjectsStateType, UserProjectImageUpdate } from '../MyProjectsInitialState'

export enum MyProjectsStateTypes {
  CreateUserProjects = 'CREATE_USER_PROJECTS',
  UpdateUserProjectImage = 'UPDATE_USER_PROJECTS_IMAGE',
  ResetUserProjects = 'RESET_USER_PROJECTS'
}

export type MyProjectsPayloadType = {
  [MyProjectsStateTypes.CreateUserProjects]: MyProjectsStateType
  [MyProjectsStateTypes.UpdateUserProjectImage]: UserProjectImageUpdate
  [MyProjectsStateTypes.ResetUserProjects]: undefined
}

export type MyProjectsActions = ActionMap<MyProjectsPayloadType>[keyof ActionMap<MyProjectsPayloadType>]

export type MyProjectsReducerType = (state: MyProjectsStateType, action: DispatchPropsType) => MyProjectsStateType
