import { DispatchPropsType } from 'store/AppContext.types'
import { ActionMap } from 'store/helpers'
import { AuthUserStateType } from 'store/initialState/AuthUserInitialState'

export enum AuthUserStateTypes {
  CreateCognitoUserSessionData = 'CREATE_USER_SESSION_DATA',
  CreateCognitoUserData = 'CREATE_COGNITO_USER_DATA'
}

export type AuthUserPayloadType = {
  [AuthUserStateTypes.CreateCognitoUserSessionData]: AuthUserStateType
  [AuthUserStateTypes.CreateCognitoUserData]: AuthUserStateType
}

export type AuthUserActions = ActionMap<AuthUserPayloadType>[keyof ActionMap<AuthUserPayloadType>]

export type AuthUserReducerType = (state: AuthUserStateType, action: DispatchPropsType) => AuthUserStateType
