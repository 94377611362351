import React, { FC } from 'react'
import Header from 'components/shared/Header'
import Footer from 'components/shared/Footer'

const LoggedMenu: FC = ({ children }) => (
  <>
    <Header />
    <div className="wrapper_content">{children}</div>
    <Footer />
  </>
)

export default LoggedMenu
