export const amplifyConfig = {
  Auth: {
    mandatorySignIn: true,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID || '',
    identityPoolRegion: process.env.REACT_APP_COGNITO_REGION || '',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
    region: process.env.REACT_APP_COGNITO_REGION || '',
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || ''
  }
}

export const apiConfig = {
  apiKey: process.env.REACT_APP_API_KEY || ''
}
