import { InitialStateType } from 'store/initialState'
import { AuthUserStateType } from 'store/initialState/AuthUserInitialState'
import {
  ProjectRegistrationStateType,
  ProjectRegistrationActions,
  ProjectRegistrationReducer
} from 'components/ProjectRegistration/ProjectRegistrationStore'
import { MyProjectsStateType, MyProjectsActions, MyProjectsReducer } from 'components/MyProjects/MyProjectsStore'

import { RoutesActions, RoutesReducer, RoutesStateType } from 'routes/RoutesStore'
import { AuthUserActions, AuthUserReducer } from './AuthUserReducer'

type MainReducerType = (
  { AuthUser, ProjectRegistration }: InitialStateType,
  action: AuthUserActions | ProjectRegistrationActions | RoutesActions | MyProjectsActions
) => {
  AuthUser: AuthUserStateType
  ProjectRegistration: ProjectRegistrationStateType
  Routes: RoutesStateType
  MyProjects: MyProjectsStateType
}

export const mainReducer: MainReducerType = ({ AuthUser, ProjectRegistration, Routes, MyProjects }, action) => ({
  AuthUser: AuthUserReducer(AuthUser, action),
  ProjectRegistration: ProjectRegistrationReducer(ProjectRegistration, action),
  Routes: RoutesReducer(Routes, action),
  MyProjects: MyProjectsReducer(MyProjects, action)
})
