import React, { FC } from 'react'
import Footer from '../Footer'
import Header from '../Header'

const OpenMenu: FC = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
)

export default OpenMenu
