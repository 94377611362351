/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Auth } from 'aws-amplify'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { AppContext } from 'store'
import { popupEmitter } from 'components/shared/AlertEventPopup'
import { spinnerEmitter } from 'components/shared/Spinner'
import { LOGIN_ROUTE, MY_PROJECTS_ROUTE, MY_ACCOUNT_ROUTE, SIGNUP_ROUTE, ROOT_ROUTE } from 'routes/AppRoutes.consts'
import { CognitoErrorType } from 'services/AWSAmplify'
import { HistoryLocationType } from './Header.types'

import logoOficial from '../../../assets/imgs/logooficial.png'
import LogoWhite from '../../../assets/imgs/m2crowdlogo-white.png'
import icoUser from '../../../assets/imgs/ico_user_blue.svg'
import icoIn from '../../../assets/imgs/in.svg'
import icoInst from '../../../assets/imgs/inst.svg'
import icoTw from '../../../assets/imgs/tw.svg'
import icoFb from '../../../assets/imgs/fb.svg'

import './Header.scss'

const NotAuthItems = ({ location: { pathname } }: HistoryLocationType): JSX.Element => (
  <div className="box_opts_link">
    <span className="ico_header">
      <img src={icoUser} alt="User" />
    </span>
    <ul className="navbar-nav">
      <li className="nav-item ">
        <Link className="nav-link" to={LOGIN_ROUTE}>
          LOGIN
        </Link>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to={SIGNUP_ROUTE}>
          REGISTRO
        </Link>
      </li>
    </ul>
  </div>
)

const Header: FC = () => {
  const {
    state: {
      AuthUser: { isAuthenticated, CognitoUser, CognitoUserSessionData }
    }
  } = useContext(AppContext)
  const [showLogoutAlert, showLogoutAlertSetter] = useState(false)
  const [showSuccessAlert, showSuccessAlertSetter] = useState(false)
  const history = useHistory() as HistoryLocationType

  const onCloseSessionConfirm = () => {
    showLogoutAlertSetter(false)
    showSuccessAlertSetter(true)
  }

  const onCancel = () => showLogoutAlertSetter(false)

  const errorPopup = (error: unknown) => {
    const cognitoError = error as CognitoErrorType
    const errorMessage = cognitoError.message ? `${cognitoError.message}` : 'Por favor vuelve a intentarlo más tarde'
    popupEmitter.emit('errorPopup', {
      showPopupErrorAlert: true,
      title: 'Error 😔',
      message: `Ha ocurrido un error al iniciar sesión: ${errorMessage}`
    })
  }

  const onConfirm = async () => {
    try {
      spinnerEmitter.emit('loading', true)
      await Auth.signOut()
      showSuccessAlertSetter(false)
      spinnerEmitter.emit('loading', false)
      window.location.pathname = ROOT_ROUTE
    } catch (error) {
      showSuccessAlertSetter(false)
      spinnerEmitter.emit('loading', false)
      errorPopup(error)
    }
  }

  const [isActive, setIsActive] = useState('')

  const ActiveMenu = () => {
    setIsActive(isActive === '' ? 'is_active' : '')
  }

  const [activeBtn, setStateBtn] = useState('')
  const BtnSet = () => {
    setStateBtn(activeBtn === '' ? 'is_visible' : '')
  }

  const MySetFun = () => {
    BtnSet()
    ActiveMenu()
  }

  const AuthItems = () => (
    <div className="box_opts_logeado">
      <div className="opts_desktop">
        <Link className="nav-link " to={MY_PROJECTS_ROUTE}>
          MIS PROYECTOS
        </Link>
        <DropdownButton
          className="link_dropdown"
          menuAlign="right"
          title={CognitoUser?.attributes['custom:name'] || CognitoUserSessionData.idToken.payload.email}
          id="dropdown-menu-align-right"
        >
          <Link className="dropdown-item" to={MY_ACCOUNT_ROUTE}>
            Mi cuenta
          </Link>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => showLogoutAlertSetter(true)}>Cerrar sesión</Dropdown.Item>
        </DropdownButton>
      </div>
      <div className="opts_mobile">
        <div className="opts_mobile_user">
          <img src={icoUser} alt="User" />
          <span>{CognitoUser?.attributes['custom:name'] || CognitoUserSessionData.idToken.payload.email}</span>
        </div>
        <div className="opts_mobile_links">
          <Link className="nav-link " to={MY_PROJECTS_ROUTE}>
            MIS PROYECTOS
          </Link>
          <Link className="nav-link" to={MY_ACCOUNT_ROUTE}>
            MI CUENTA
          </Link>
          <div className="nav-link " onClick={() => showLogoutAlertSetter(true)}>
            CERRAR SESIÓN
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <header className="wrapper_header sticky-top  shadow-sm ">
        <div className="drawer_header">
          <Link className="navbar-brand" to={ROOT_ROUTE}>
            <img alt="Logo" src={logoOficial} />
          </Link>
          <span className={`btn_menu ${activeBtn}`} onClick={MySetFun}>
            <span />
            <span />
            <span />
          </span>
          <div className={`drawer_navbar ${isActive}`}>
            {isAuthenticated ? AuthItems() : NotAuthItems(history)}
            <div className="box_logo_mobile">
              <Link to="/">
                <img src={LogoWhite} alt="Logo" />
              </Link>
            </div>
            <div className="box_social_mobile">
              <a href="https://www.linkedin.com/company/m2crowd/" target="_blank" rel="noreferrer">
                <img src={icoIn} alt="Síguenos en Linkedin" />
              </a>
              <a href="https://www.instagram.com/m2crowd/" target="_blank" rel="noreferrer">
                <img src={icoInst} alt="Síguenos en Instagram" />
              </a>
              <a href="https://twitter.com/m2crowd" target="_blank" rel="noreferrer">
                <img src={icoTw} alt="Síguenos en Twitter" />
              </a>
              <a href="https://www.facebook.com/m2crowd" target="_blank" rel="noreferrer">
                <img src={icoFb} alt="Síguenos en Facebook" />
              </a>
            </div>
          </div>
        </div>
      </header>

      {showLogoutAlert && (
        <SweetAlert
          warning
          showCancel
          btnSize="sm"
          confirmBtnBsStyle="danger"
          title="Cerrar sesión"
          confirmBtnText="  OK  "
          cancelBtnText="Cancelar"
          onConfirm={onCloseSessionConfirm}
          onCancel={onCancel}
          closeOnClickOutside
          focusCancelBtn
        >
          ¿Está segur@ de cerrar sesión?
        </SweetAlert>
      )}
      {showSuccessAlert && (
        <SweetAlert
          success
          title="¡Hasta pronto!"
          btnSize="sm"
          confirmBtnText="  OK  "
          onConfirm={onConfirm}
          closeOnClickOutside
          timeout={2000}
          showConfirm={false}
        >
          ¡Te esperamos de vuelta!
        </SweetAlert>
      )}
    </>
  )
}

export default Header
