import { AuthUserStateType } from './AuthUserInitialState.types'

export const AuthUserInitialState: AuthUserStateType = {
  CognitoUserSessionData: {
    idToken: {
      payload: {
        email: ''
      },
      jwtToken: ''
    }
  },
  CognitoUser: {
    Session: null,
    attributes: {
      sub: '',
      website: '',
      email_verified: false,
      email: '',
      'custom:name': '',
      'custom:surname': ''
    },
    authenticationFlowType: '',
    client: {},
    keyPrefix: '',
    pool: {},
    preferredMFA: '',
    signInUserSession: {},
    storage: {},
    userDataKey: '',
    username: ''
  },
  isAuthenticated: false
}
