import React, { FC, useEffect, useState } from 'react'
import EventEmitter from 'eventemitter3'
import Spinner from './Spinner'

import './Spinner.scss'

export const globalSpinnerEmitter = new EventEmitter()

const GlobalSpinner: FC = () => {
  const [loading, loadingSetter] = useState(false)

  useEffect(() => {
    globalSpinnerEmitter.on('loading', (isLoading: boolean) => loadingSetter(isLoading))
    return () => {
      globalSpinnerEmitter.off('loading')
    }
  }, [])

  return <>{loading && <Spinner />}</>
}

export default GlobalSpinner
