import React, { FC } from 'react'
import { InputGroup } from 'react-bootstrap'
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons'
import { ShowPasswordButtonType } from './ShowPasswordButton.types'

const ShowPasswordButton: FC<ShowPasswordButtonType> = ({ value, onClick }) => (
  <InputGroup.Append className="pointer">
    <InputGroup.Text onClick={() => onClick(!value)}>
      {!value && <EyeSlashFill />}
      {value && <EyeFill />}
    </InputGroup.Text>
  </InputGroup.Append>
)

export default ShowPasswordButton
