import { MyProjectsInitialState } from '../MyProjectsInitialState'
import { MyProjectsReducerType, MyProjectsStateTypes } from './MyProjectsReducer.types'

export const MyProjectsReducer: MyProjectsReducerType = (state, action) => {
  switch (action.type) {
    case MyProjectsStateTypes.CreateUserProjects:
      return { ...state, userProjects: action.payload.userProjects }
    case MyProjectsStateTypes.UpdateUserProjectImage: {
      const userProjects = [...state.userProjects]
      const userProject = { ...action.payload.userProject }
      const indexOfProjectData = state.userProjects.indexOf(action.payload.userProject)
      userProject.projectImage = action.payload.projectImage
      userProjects[indexOfProjectData] = userProject
      return { ...state, userProjects: [...userProjects] }
    }
    case MyProjectsStateTypes.ResetUserProjects:
      return { ...MyProjectsInitialState }
    default:
      return state
  }
}
