import { RoutesInitialState } from '../RoutesInitialState'
import { RoutesReducerType, RoutesStateTypes } from './RoutesReducer.types'

export const RoutesReducer: RoutesReducerType = (state, action) => {
  switch (action.type) {
    case RoutesStateTypes.CreateRedirect:
      return { ...state, redirectTo: action.payload.redirectTo, redirecting: true }
    case RoutesStateTypes.ResetRedirect:
      return { ...RoutesInitialState }
    default:
      return state
  }
}
