export const UNDERSTANDING = '/entendimiento'
export const HOME_ROUTE = '/home'
export const LOGIN_ROUTE = '/login'
export const MY_ACCOUNT_ROUTE = '/mi-cuenta'
export const MY_PROJECTS_ROUTE = '/mis-proyectos'
export const NOT_FOUND_ROUTE = '/not-found'
export const NDA = '/acuerdo-de-confidencialidad'
export const PASSWORD_RECOVERY_ROUTE = '/recuperar-contraseña'
export const PROJECT_DETAIL_MATCH_PARAM = '/:projectId'
export const PROJECT_DETAIL_ROUTE = '/detalle-proyecto'
export const ROOT_ROUTE = '/'
export const SIGNUP_ROUTE = '/registro'
export const PROJECT_REGISTRATION_ROUTE = '/sube-tu-proyecto-pt1'
export const LEGAL_REGISTRATION_ROUTE = '/registrar-datos-legales'
export const PROJECT_REGISTRATION_THANKS_ROUTE = '/muchas-gracias'
export const REVIEW = '/revision-subcomite'
export const RENEGOCIATE_OFFER = '/renegociar-oferta'
export const RENEGOCIATE_CONTRACTS = '/renegociar-contratos'
export const UNDER_CONSTRUCTION_ROUTE = '/under-construction'
export const DETAILS_REGISTRATION_ROUTE = '/registrar-detalles'
