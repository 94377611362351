import React, { FC, useEffect, useState } from 'react'
import EventEmitter from 'eventemitter3'
import Spinner from './Spinner'

import './Spinner.scss'

export const spinnerEmitter = new EventEmitter()

const EventSpinner: FC = () => {
  const [loading, loadingSetter] = useState(false)

  useEffect(() => {
    spinnerEmitter.on('loading', (isLoading: boolean) => loadingSetter(isLoading))
    return () => {
      spinnerEmitter.off('loading')
    }
  }, [])

  return <>{loading && <Spinner />}</>
}

export default EventSpinner
