import React, { FC, Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { Spinner } from 'components/shared/Spinner'

import LazyImport from 'routes/helpers/LazyImport'
import AuthRoutesWrapper from 'routes/helpers/AuthRoutesWrapper'
import {
  UNDERSTANDING,
  HOME_ROUTE,
  LOGIN_ROUTE,
  MY_ACCOUNT_ROUTE,
  MY_PROJECTS_ROUTE,
  NOT_FOUND_ROUTE,
  RENEGOCIATE_OFFER,
  PASSWORD_RECOVERY_ROUTE,
  PROJECT_DETAIL_MATCH_PARAM,
  PROJECT_DETAIL_ROUTE,
  ROOT_ROUTE,
  SIGNUP_ROUTE,
  PROJECT_REGISTRATION_ROUTE,
  PROJECT_REGISTRATION_THANKS_ROUTE,
  UNDER_CONSTRUCTION_ROUTE,
  NDA,
  LEGAL_REGISTRATION_ROUTE,
  REVIEW,
  DETAILS_REGISTRATION_ROUTE,
  RENEGOCIATE_CONTRACTS
} from './AppRoutes.consts'

const Understanding = LazyImport(async () => import('components/Understanding/Understanding'))
const ForgotPassword = LazyImport(async () => import('components/AuthUser/ForgotPassword'))
const Login = LazyImport(async () => import('components/AuthUser/Login'))
const MyAccount = LazyImport(async () => import('components/MyAccount'))
const MyProjects = LazyImport(async () => import('components/MyProjects'))
const RenegociateOffer = LazyImport(async () => import('components/ReviewRegistration/RenegotiateOffer'))
const OpenHome = LazyImport(async () => import('components/OpenHome'))
const ProjectDetail = LazyImport(async () => import('components/MyProjects/ProjectsCatalog/ProjectDetail'))
const SignUp = LazyImport(async () => import('components/AuthUser/SignUp'))
const ProjectRegistration = LazyImport(async () => import('components/ProjectRegistration/ProjectRegistration'))
const ProjectRegistrationThanks = LazyImport(
  async () => import('components/ProjectRegistration/ProjectRegistrationThanks')
)
const UnderConstruction = LazyImport(async () => import('components/UnderConstruction'))
const NDARegistration = LazyImport(async () => import('components/NDARegistration/NDARegistration'))
const LegalRegistration = LazyImport(async () => import('components/LegalRegistration'))
const Review = LazyImport(async () => import('components/ReviewRegistration'))
const DetailsRegistration = LazyImport(async () => import('components/DetailsRegistration'))
const RenegociateContracts = LazyImport(async () => import('components/ReviewRegistration/RenegotiateContracts'))

const AppRoutes: FC = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <AuthRoutesWrapper
        exact={true}
        path={ROOT_ROUTE}
        component={OpenHome}
        menuType="openHomeMenu"
        needAuthentication={false}
      />
      <AuthRoutesWrapper path={UNDERSTANDING} component={Understanding} menuType="openMenu" needAuthentication={true} />
      <AuthRoutesWrapper
        path={HOME_ROUTE}
        component={UnderConstruction}
        menuType="loggedMenu"
        needAuthentication={true}
      />
      <AuthRoutesWrapper path={LOGIN_ROUTE} component={Login} menuType="openMenu" needAuthentication={false} />
      <AuthRoutesWrapper path={MY_ACCOUNT_ROUTE} component={MyAccount} menuType="openMenu" needAuthentication={true} />
      <AuthRoutesWrapper
        path={MY_PROJECTS_ROUTE}
        component={MyProjects}
        menuType="loggedMenu"
        needAuthentication={true}
      />
      <AuthRoutesWrapper
        path={`${NDA}${PROJECT_DETAIL_MATCH_PARAM}`}
        component={NDARegistration}
        menuType="loggedMenu"
        needAuthentication={true}
      />
      <AuthRoutesWrapper
        path={`${RENEGOCIATE_OFFER}${PROJECT_DETAIL_MATCH_PARAM}`}
        component={RenegociateOffer}
        menuType="loggedMenu"
        needAuthentication={true}
      />
      <AuthRoutesWrapper
        path={`${RENEGOCIATE_CONTRACTS}${PROJECT_DETAIL_MATCH_PARAM}`}
        component={RenegociateContracts}
        menuType="loggedMenu"
        needAuthentication={true}
      />
      <AuthRoutesWrapper
        path={PASSWORD_RECOVERY_ROUTE}
        component={ForgotPassword}
        menuType="openMenu"
        needAuthentication={false}
      />
      <AuthRoutesWrapper
        path={`${PROJECT_DETAIL_ROUTE}${PROJECT_DETAIL_MATCH_PARAM}`}
        component={ProjectDetail}
        menuType="loggedMenu"
        needAuthentication={true}
      />
      <AuthRoutesWrapper
        path={`${REVIEW}${PROJECT_DETAIL_MATCH_PARAM}`}
        component={Review}
        menuType="loggedMenu"
        needAuthentication={true}
      />
      <AuthRoutesWrapper path={SIGNUP_ROUTE} component={SignUp} menuType="openMenu" needAuthentication={false} />
      <AuthRoutesWrapper
        path={PROJECT_REGISTRATION_ROUTE}
        component={ProjectRegistration}
        menuType="openMenu"
        needAuthentication={false}
      />
      <AuthRoutesWrapper
        path={PROJECT_REGISTRATION_THANKS_ROUTE}
        component={ProjectRegistrationThanks}
        menuType="openMenu"
        needAuthentication={false}
      />
      <AuthRoutesWrapper
        path={`${LEGAL_REGISTRATION_ROUTE}${PROJECT_DETAIL_MATCH_PARAM}`}
        component={LegalRegistration}
        menuType="loggedMenu"
        needAuthentication={true}
      />
      <AuthRoutesWrapper
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        path={`${DETAILS_REGISTRATION_ROUTE}${PROJECT_DETAIL_MATCH_PARAM}`}
        component={DetailsRegistration}
        menuType="loggedMenu"
        needAuthentication={true}
      />
      <AuthRoutesWrapper
        path={UNDER_CONSTRUCTION_ROUTE}
        component={UnderConstruction}
        menuType="openMenu"
        needAuthentication={false}
      />
      <AuthRoutesWrapper
        path={NOT_FOUND_ROUTE}
        component={UnderConstruction}
        menuType="openMenu"
        needAuthentication={false}
      />
      <Redirect to={NOT_FOUND_ROUTE} />
    </Switch>
  </Suspense>
)

export default AppRoutes
