import React, { FC, useEffect, useState } from 'react'
import EventEmitter from 'eventemitter3'
import InfoProgressBar from './InfoProgressBar'
import { InfoProgressBarType } from './InfoProgressBar.types'

export const progressBarEmitter = new EventEmitter()

const EventInfoProgressBar: FC = () => {
  const [progress, progressSetter] = useState(false)
  const [infoMessage, infoMessageSetter] = useState('')
  const [status, statusSetter] = useState(0)
  const [min, minSetter] = useState(0)
  const [max, maxSetter] = useState(0)

  useEffect(() => {
    progressBarEmitter.on(
      'progress',
      ({ showProgress, progressStatus, message, valueMin, valueMax }: InfoProgressBarType) => {
        progressSetter(showProgress)
        statusSetter(progressStatus)
        infoMessageSetter(message)
        maxSetter(valueMax)
        minSetter(valueMin)
      }
    )
    return () => {
      progressBarEmitter.off('progress')
    }
  }, [])

  return (
    <>
      {progress && (
        <InfoProgressBar
          showProgress={progress}
          progressStatus={status}
          message={infoMessage}
          valueMin={min}
          valueMax={max}
        />
      )}
    </>
  )
}

export default EventInfoProgressBar
