import React, { FC } from 'react'
import PuffLoader from 'react-spinners/PuffLoader'
import './Spinner.scss'

const Spinner: FC = () => (
  <div className="loading-without-background">
    <div className="spinner">
      <PuffLoader size={100} color="#00164E" />
    </div>
    <div className="loading-background" />
  </div>
)

export default Spinner
