import { MappingType } from 'sharedTypes'
import { DispatchPropsType } from 'store/AppContext.types'
import { ActionMap } from 'store/helpers'
import { ProjectRegistrationStateType } from '../ProjectRegistrationInitialState'

export enum ProjectRegistrationStateTypes {
  CreateProjectRegistrationFormData = 'CREATE_PROJECT_REGISTRATION_FORM_DATA',
  CreateProjectData = 'CREATE_PROJECT_DATA',
  ProjectOnChange = 'PROJECT_ON_CHANGE',
  OnChangeProjectRegistrationInput = 'ON_CHANGE_PROJECT_REGISTRATION_INPUT',
  ResetProjectRegistrationFormData = 'RESET_PROJECT_REGISTRATION_FORM_DATA'
}

export type ProjectRegPayloadType = {
  [ProjectRegistrationStateTypes.CreateProjectRegistrationFormData]: ProjectRegistrationStateType
  [ProjectRegistrationStateTypes.CreateProjectData]: ProjectRegistrationStateType
  [ProjectRegistrationStateTypes.ProjectOnChange]: MappingType
  [ProjectRegistrationStateTypes.OnChangeProjectRegistrationInput]: MappingType
  [ProjectRegistrationStateTypes.ResetProjectRegistrationFormData]: undefined
}

export type ProjectRegistrationActions = ActionMap<ProjectRegPayloadType>[keyof ActionMap<ProjectRegPayloadType>]

export type ProjectRegistrationReducerType = (
  state: ProjectRegistrationStateType,
  action: DispatchPropsType
) => ProjectRegistrationStateType
