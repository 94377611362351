import React, {
  Attributes,
  ComponentClass,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import MenuWrapper from 'components/shared/MenuWrapper'
import { AppContext, CognitoUserSessionDataType, AuthUserStateTypes } from 'store'
import { LOGIN_ROUTE } from 'routes/AppRoutes.consts'
import { AuthRoutesWrapperType } from './AuthRoutesWrapper.types'

const AuthRoutesWrapper: AuthRoutesWrapperType = ({ exact = false, path, component, menuType, needAuthentication }) => {
  const {
    state: {
      AuthUser: { isAuthenticated }
    },
    dispatch
  } = useContext(AppContext)
  const [isAuthenticating, isAuthenticatingSetter] = useState(true)

  const onLoad = useCallback(async () => {
    try {
      const cognitoUserSession = await Auth.currentSession()
      const cognitoUser = await Auth.currentAuthenticatedUser()
      const cognitoUserSessionDataUnknown = cognitoUserSession as unknown
      const cognitoUserSessionData = cognitoUserSessionDataUnknown as CognitoUserSessionDataType
      dispatch({
        type: AuthUserStateTypes.CreateCognitoUserSessionData,
        payload: { CognitoUserSessionData: cognitoUserSessionData, CognitoUser: cognitoUser }
      })
      isAuthenticatingSetter(false)
    } catch (error) {
      localStorage.clear()
      isAuthenticatingSetter(false)
    }
  }, [dispatch])

  useEffect(() => {
    void onLoad()
  }, [onLoad])

  const RouterComponent = useMemo(
    () => (
      routerProps:
        | (Attributes & ComponentClass<any, any>)
        | (Attributes & FunctionComponent<any>)
        | (Attributes & ComponentClass<any>)
        | (Attributes & FunctionComponent<any>)
        | null
        | undefined
    ): JSX.Element => (
      <MenuWrapper menuType={menuType} {...routerProps}>
        {React.createElement(component, routerProps)}
      </MenuWrapper>
    ),
    [component, menuType]
  )
  if (isAuthenticating) return <></>
  if (!needAuthentication) return <Route exact={exact} path={path} component={RouterComponent} />
  if (isAuthenticated) return <Route exact={exact} path={path} component={RouterComponent} />
  return <Redirect to={LOGIN_ROUTE} />
}

export default AuthRoutesWrapper
