import React, { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Amplify from 'aws-amplify'

import Routes from 'routes'
import { AppProvider } from 'store'
import { amplifyConfig } from 'services/AWSAmplify/amplifyConfig'
import { EventSpinner, GlobalSpinner } from 'components/shared/Spinner'
import { EventInfoProgressbar } from 'components/shared/infoProgressBar'
import { EventPopup } from 'components/shared/AlertEventPopup'
import LoginPopup from 'components/AuthUser/LoginPopup'

Amplify.configure(amplifyConfig)

const App: FC = () => (
  <AppProvider>
    <EventSpinner />
    <GlobalSpinner />
    <EventInfoProgressbar />
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
    <LoginPopup />
    <EventPopup />
  </AppProvider>
)

export default App
