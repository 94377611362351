import React, { FC } from 'react'
import './InfoProgressBar.scss'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { InfoProgressBarType } from './InfoProgressBar.types'

const InfoProgressBar: FC<InfoProgressBarType> = ({ showProgress, progressStatus, message, valueMin, valueMax }) => (
  <div className={`"vh-100 container-bar ${showProgress ? 'show' : 'hide'}`}>
    <div className="info-progress col-4 text-center mx-auto">
      <ProgressBar animated now={progressStatus} label={message} min={valueMin} max={valueMax} />
    </div>
  </div>
)

export default InfoProgressBar
