import React, { FC, useContext, useEffect, useState } from 'react'
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Auth } from 'aws-amplify'
import { CognitoUser, AuthenticationDetails, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js'
import EventEmitter from 'eventemitter3'

import { spinnerEmitter } from 'components/shared/Spinner'
import ShowPasswordButton from 'components/shared/ShowPasswordButton'
import { popupEmitter, PopupErrorDataType } from 'components/shared/AlertEventPopup'
import { HandleReactInputOnChange, ReactChangeEventType } from 'sharedTypes'
import { CognitoErrorType } from 'services/AWSAmplify'
import { AppContext, AuthUserStateTypes, CognitoUserSessionDataType } from 'store'
import { amplifyConfig } from 'services/AWSAmplify/amplifyConfig'

import { GetCognitoUserType } from '../Login/Login.types'
import { LoginPopupEmitterType, LoginPopupPropsType } from './LoginPopup.types'

export const loginPopupEmitter = new EventEmitter()

interface LoginFormValuesInterface {
  email: string
  password: string
}

const schema = Yup.object({
  email: Yup.string().email('El email debe ser válido').required('"EMAIL" es un campo requerido'),
  password: Yup.string()
    .required('"CONTRASEÑA" es un campo requerido')
    .min(8, 'La contraseña debe contener al menos 8 caracteres')
})

const LoginPopup: FC<LoginPopupPropsType> = ({ showPopupAlert = false }) => {
  const [showLoginFormPopup, showLoginFormPopupSetter] = useState(false)
  const { dispatch } = useContext(AppContext)
  const [showPassword, showPasswordToggle] = useState(false)
  const [showSuccessAlert, showSuccessAlertSetter] = useState(false)
  const [formlValues, formlValuesSetter] = useState<LoginFormValuesInterface>({ email: '', password: '' })

  useEffect(() => {
    loginPopupEmitter.on('loginPopup', ({ showLoginPopup }: LoginPopupEmitterType) => {
      showLoginFormPopupSetter(showLoginPopup)
    })
    return () => void loginPopupEmitter.off('loginPopup')
  }, [])

  useEffect(() => {
    spinnerEmitter.emit('loading', false)
    showLoginFormPopupSetter(showPopupAlert)
  }, [showPopupAlert])

  const errorPopup = (error: unknown) => {
    const cognitoError = error as CognitoErrorType
    const errorType: PopupErrorDataType = getError(cognitoError)
    popupEmitter.emit(errorType.type, {
      showPopupErrorAlert: true,
      title: errorType.title,
      message: `Ha ocurrido un error al iniciar sesión: ${errorType.message}`
    })
  }

  const getError = (error: CognitoErrorType): PopupErrorDataType => {
    if (error.code === 'UserNotConfirmedException')
      return {
        type: 'infoPopup',
        title: 'Aviso',
        message: 'No has confirmado tu cuenta, por favor ingresa a la liga enviada al email proporcionado'
      }
    if (error.code === 'NotAuthorizedException')
      return {
        type: 'errorPopup',
        title: 'Error 😔',
        message: 'Email o contraseña no válido'
      }
    return {
      type: 'errorPopup',
      title: 'Error 😔',
      message: error.message ? `${error.message}` : 'Por favor vuelve a intentarlo más tarde'
    }
  }

  const getCognitoUserPool: GetCognitoUserType = (email, password) => {
    const Pool = new CognitoUserPool({
      UserPoolId: amplifyConfig.Auth.userPoolId,
      ClientId: amplifyConfig.Auth.userPoolWebClientId
    })
    const user = new CognitoUser({ Username: email, Pool })
    const authDetails = new AuthenticationDetails({ Username: email, Password: password })
    return { user, authDetails }
  }

  const getCognitoUser = async (data: CognitoUserSession) => {
    const cognitoUserSessionDataUnknown = data as unknown
    const cognitoUserSessionData = cognitoUserSessionDataUnknown as CognitoUserSessionDataType
    const cognitoUser = await Auth.currentAuthenticatedUser()
    dispatch({
      type: AuthUserStateTypes.CreateCognitoUserSessionData,
      payload: { CognitoUserSessionData: cognitoUserSessionData, CognitoUser: cognitoUser }
    })
    showSuccessAlertSetter(true)
    spinnerEmitter.emit('loading', false)
  }

  const onSubmit = (values: LoginFormValuesInterface) => {
    try {
      spinnerEmitter.emit('loading', true)
      const { user, authDetails } = getCognitoUserPool(values.email, values.password)
      user.authenticateUser(authDetails, {
        onSuccess: data => {
          void getCognitoUser(data)
        },
        onFailure: error => {
          spinnerEmitter.emit('loading', false)
          errorPopup(error)
        },
        newPasswordRequired: data => {
          spinnerEmitter.emit('loading', false)
          // TODO: Rresolve this case for new password request
        }
      })
    } catch (error) {
      spinnerEmitter.emit('loading', false)
      errorPopup(error)
    }
  }

  const handleInputChange: HandleReactInputOnChange = ({ event, handleChange }) => {
    handleChange(event)
    formlValuesSetter({ [event.currentTarget.name]: event.currentTarget.value, ...formlValues })
  }

  const onConfirm = () => {
    showSuccessAlertSetter(false)
    showLoginFormPopupSetter(false)
  }

  if (!showLoginFormPopup) return <></>

  return (
    <SweetAlert show={showLoginFormPopup} onConfirm={onSubmit} title="Login" type="controlled" showConfirm={false}>
      <Formik enableReinitialize validationSchema={schema} onSubmit={onSubmit} initialValues={formlValues}>
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <div className="wrapper_login ptop hg_content">
            <div className="container">
              <div className="text-center mb-5">
                <h1 className="m2_title t_small">La sesión ha caducado, por favor vuelve a iniciar sesión.</h1>
              </div>
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="justify-content-md-center">
                  <Col sm={12} md={8} lg={5}>
                    <div className="row">
                      <Col sm={12}>
                        <Form.Group className="m2-form-group has_icons" controlId="form.Name">
                          <Form.Control
                            name="email"
                            type="email"
                            placeholder="Ingresa email"
                            autoComplete="email"
                            value={values.email}
                            onChange={(event: ReactChangeEventType) => handleInputChange({ event, handleChange })}
                            onBlur={handleBlur}
                            isValid={touched.email && !errors.email}
                            isInvalid={touched.email && !!errors.email}
                            required
                          />
                          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group className="m2-form-group" controlId="form.password">
                          <InputGroup>
                            <Form.Control
                              className=""
                              name="password"
                              autoComplete="new-password"
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Contraseña"
                              value={values.password}
                              onChange={(event: ReactChangeEventType) => handleInputChange({ event, handleChange })}
                              onBlur={handleBlur}
                              isValid={touched.password && !errors.password}
                              isInvalid={touched.password && !!errors.password}
                              required
                            />
                            <ShowPasswordButton value={showPassword} onClick={showPasswordToggle} />
                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button variant="success" className="mb-3" type="submit">
                    INGRESAR A MI CUENTA
                  </Button>
                </div>
              </Form>
            </div>
            <div className="bg_m2crowd" />
          </div>
        )}
      </Formik>
      {showSuccessAlert && (
        <SweetAlert
          success
          title="¡Exito!"
          btnSize="sm"
          confirmBtnText="  OK  "
          onConfirm={onConfirm}
          closeOnClickOutside
          timeout={2000}
          showConfirm={false}
        >
          ¡Inicio de sesión exitoso!
        </SweetAlert>
      )}
    </SweetAlert>
  )
}

export default LoginPopup
