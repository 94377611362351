import React, { lazy } from 'react'
import { LazyImportType, ComponentLoadedType } from './LazyImport.types'

const LazyImport: LazyImportType = asyncLoaderFunction => {
  const LazyComponent = lazy(asyncLoaderFunction)
  const componentLoaded: ComponentLoadedType = props => <LazyComponent {...props} />
  return componentLoaded
}

export default LazyImport
