import React, { createContext, FC, useReducer } from 'react'

import initialState from './initialState'
import { AppContextType } from './AppContext.types'
import { mainReducer } from './reducers'

const AppContext = createContext<AppContextType>({ state: initialState, dispatch: () => null })

const AppProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)
  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
}

export { AppContext, AppProvider }
