import { DispatchPropsType } from 'store/AppContext.types'
import { ActionMap } from 'store/helpers'
import { RoutesStateType } from '../RoutesInitialState'

export enum RoutesStateTypes {
  CreateRedirect = 'CREATE_REDIRECT',
  ResetRedirect = 'RESET_REDIRECT'
}

export type RoutesPayloadType = {
  [RoutesStateTypes.CreateRedirect]: RoutesStateType
  [RoutesStateTypes.ResetRedirect]: undefined
}

export type RoutesActions = ActionMap<RoutesPayloadType>[keyof ActionMap<RoutesPayloadType>]

export type RoutesReducerType = (state: RoutesStateType, action: DispatchPropsType) => RoutesStateType
