import React, { FC } from 'react'
import Footer from 'components/shared/Footer'

const OpenHomeMenu: FC = ({ children }) => (
  <>
    {children}
    <Footer />
  </>
)

export default OpenHomeMenu
