import { ProjectRegistrationStateType } from './ProjectRegistrationInitialState.types'

export const ProjectRegistrationInitialState: ProjectRegistrationStateType = {
  ProjectRegistrationForm: {
    Name: '',
    Nombre: '',
    Apellidos: '',
    Email: '',
    Telefono: '',
    Que_te_describe: '',
    Etapa_del_proyecto: '',
    Monto_de_financiamiento: 5000000,
    Pagina_web_de_la_empresa: '',
    Pagina_web_del_proyecto: '',
    URL_Gmaps: ''
  },
  CurrentProject: {
    score: 0,
    created: '',
    email: '',
    status: '',
    modifiedBy: {
      id: '',
      email: '',
      name: ''
    },
    modified: '',
    projectRenders: [],
    presalePrices: [],
    phoneNumber: '',
    id: '',
    Figura_juridica: '',
    Tipo_de_indentificacion: '',
    Numero_interior_fiscal: '',
    Municipio_fiscal: '',
    CP_fiscal: '',
    Colonia_fiscal: '',
    Calle_y_Numero_fiscal: '',
    Estado_fiscal: '',
    Lugar_donde_te_encuentras_actualmente_para_firmar: '',
    URL_identificacion: [],
    URL_identificacion_reverso: [],
    URL_comprobante_de_domicilio: [],
    URL_escritura_modificaciones_estatutarias_y_admin: [],
    URL_escritura_constitutiva: [],
    URL_constancia_de_situacion_fiscal: [],
    RFC_del_representante_legal: '',
    CURP_del_representante_legal: '',
    RFC_de_la_empresa: '',
    Razon_Social: '',
    Numero_de_notario: '',
    Numero_de_escritura_publica: '',
    Nombre_del_notario: '',
    Nombre_comercial_de_la_empresa: '',
    Fecha_de_escritura: '',
    Estado_del_notario: '',
    RFC: '',
    CURP: '',
    projectId: '',
    Plazo_de_financiamiento_meses: 0
  }
}
